import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, ExtraOptions } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ProductsComponent } from './products/products.component';
import { ViewCartComponent } from './view-cart/view-cart.component';
import { GalleryComponent } from './gallery/gallery.component';
import { CropGuidanceComponent } from './crop-guidance/crop-guidance.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { AwardsComponent } from './awards/awards.component';
import { OnlineEnquiryComponent } from './online-enquiry/online-enquiry.component';
import { NotificationComponent } from './notification/notification.component';
import { CustomersComponent } from './Admin/customers/customers.component'
import { AddproductComponent } from './Admin/addproduct/addproduct.component';
import { DistributorsComponent } from './Admin/distributors/distributors.component';
import { EnquiriesComponent } from './Admin/enquiries/enquiries.component';
import { RandDComponent } from './rand-d/rand-d.component';
import { RdnewsComponent } from './rdnews/rdnews.component';
import { RdchilliComponent } from './rdchilli/rdchilli.component';
import { RdpapayaComponent } from './rdpapaya/rdpapaya.component';
import { RnurseryyComponent } from './rnurseryy/rnurseryy.component';
import { HitechnurseryComponent } from './hitechnursery/hitechnursery.component';                  //api

const routes: Routes = [
  { 'path': '', 'redirectTo': '/home', 'pathMatch': 'full' },
  { path: "home", component: HomeComponent },
  { path: "ContactUs", component: ContactusComponent },
  { path: "AboutUs", component: AboutusComponent },
  { path: "Products/:name/:id", component: ProductsComponent },
  { path: "ViewCart", component: ViewCartComponent },
  { path: "Gallery", component: GalleryComponent },
  { path: "CropGuidance/:CropName/:Id", component: CropGuidanceComponent },
  { path: "Testimonials", component: TestimonialsComponent },
  { path: "Awards", component: AwardsComponent },
  { path: "OnlineEnquiry", component: OnlineEnquiryComponent },
  { path: "Notifications", component: NotificationComponent },
  { path: "Customers", component: CustomersComponent },
  { path: "AddProduct", component: AddproductComponent },
  { path: "Distributors", component: DistributorsComponent },
  { path: "Enquiries", component: EnquiriesComponent },
  { path: "R&D", component: RandDComponent },
  { path: "rdnews", component: RdnewsComponent },
  { path: "rdchilli", component: RdchilliComponent },
  { path: "rdpapya", component: RdpapayaComponent },
  { path: "rnuseryy", component: RnurseryyComponent },
  { path: "hitechnursery", component: HitechnurseryComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    useHash: true, preloadingStrategy: PreloadAllModules
   
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

  //preloadingStrategy: PreloadAllModules,scrollPositionRestoration: 'top'
