import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppService } from '../../app/app.service';
import { Http, Response } from '@angular/http'
import { map, throttle, debounce } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-online-enquiry',
  templateUrl: './online-enquiry.component.html',
  styleUrls: ['./online-enquiry.component.scss']
})
export class OnlineEnquiryComponent implements OnInit {
  public Obj :any= [];
  public arr = [];
  Name: any;
  constructor(public formBuilder: FormBuilder, public messageservice: MessageService, public appservice: AppService, public http: Http) {

   }

  ngOnInit() {
  }


  Validations() {
    if (this.Obj.Name == null || this.Obj.Name == '' || this.Obj.Name == undefined) {
      this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter name' });
    }
    else if (this.Obj.Email == null || this.Obj.Email == '' || this.Obj.Email == undefined) {
      this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter Email' });
    }
    //else if (this.Obj.Subject == null || this.Obj.Subject == '' || this.Obj.Subject == undefined) {
    //  this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter Subject' });
    //}
    else if (this.Obj.Phone == null || this.Obj.Phone == '' || this.Obj.Phone == undefined) {
      this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter Phone' });
    }
    else if (this.Obj.Message == null || this.Obj.Message == '' || this.Obj.Message == undefined) {
      this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter Message' });
    }
    else {
      return true;
    }
  }
  
  OnlineEnquiry() {
    debugger
    if (this.Validations()) {
      this.arr = [];
      this.arr.push({
        Name: this.Obj.Name,
        Email: this.Obj.Email,
        Subject: this.Obj.Subject,
        Mobile: this.Obj.Phone,
        Message: this.Obj.Message,
      })
      var url = this.appservice.HomeUrl + "api/Farmer/ContactUs?details=" + JSON.stringify(this.arr);
      this.http.get(url).subscribe((obj: Response) => {
        debugger
        var data = obj.json();
        if (data == "SUCCESS") {
          this.Obj = [];
          this.messageservice.add({ severity: 'success', summary: 'SUCCESS', detail: 'your message sent successfully and will concant you soon' });
          //var Url = this.appservice.HomeUrl + "api/Farmer/EnquiryMailToAdmin?Details=" + JSON.stringify(this.arr);
          //this.http.get(Url).subscribe((obj: Response) => {
          //  debugger
          //  var data = obj.json();
          //  if (data == "SUCCESS") {
          //    debugger
          //  }
          //})
        }
        else {
          this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Something went wrong' });
        }
      });
    }
   

  }

}



