import { Component, OnInit, NgZone } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Response } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map, throttle } from 'rxjs/operators';
import { AppService } from '../app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
declare var $: any;

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  providers: [ConfirmationService]
})
export class GalleryComponent implements OnInit {

CategoryImage:any;

 Crops: any;
  Homeurl: string;
  CropImages: any;
  CropVideos: any;
  MobServiceUrl: string;
  spinner: boolean = false;
  cropid: any;
  Cropname: any;
  cities:any= [];
  IMG:any=""
  IMG1:any=""
    LoginDetails: any;
    type: any;
  urls: any;
  solnimg: any = [];
  scheduleImg: any = [];
  id: any = 0;
  msgs: Message[] = [];
  InfoImg: any = [];
  image: any;
  constructor(public router: Router, public zone: NgZone, public http: Http, public route: ActivatedRoute,
    public appservice: AppService, public sanitizer: DomSanitizer, public confirmationService: ConfirmationService) {
    debugger
    this.LoginDetails = JSON.parse(sessionStorage.getItem("LoginDetails"));
    if (this.LoginDetails != null) {
      this.type = this.LoginDetails.LoginType;
    }

    this.Homeurl = localStorage.getItem('URL');
    this.spinner = true;
    this.MobServiceUrl = 'https://www.yklaboratories.com/webservices/';

    this.route.params.subscribe(params => {
      debugger
      this.cropid = '101';
      this.Cropname = '';
      debugger
      //this.getCrops();CropImages
      this.zone.run(() => {
        this.GetCropDetails(this.cropid);
       
      });
    });

  }


  ngOnInit() {
   

  }
  apply() {


   

  }

  showModal(img) {
    this.IMG = img;
    $('#myModal22').modal('show');

  }
  close() {
  }
  detectFiles(event) {
    this.IMG = ""
    this.IMG1 = ""
    debugger
    this.urls = [];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        }
        reader.readAsDataURL(file);
      }
      this.fileChange(event);
    }

  }
  fileChange(event) {
    debugger
    let fileList = event.target.files;
    if (fileList.length > 0) {
      const frmData = new FormData();
      frmData.append("UploadedImage", fileList[0]);
      var url = this.appservice.HomeUrl + '/api/Farmer/ImageUpload';
      this.http.post(url, frmData).subscribe((obj: Response) => {
        debugger
        var data = obj.json();
        if (data != null) {
          debugger
          this.IMG1 = data;
          this.IMG = this.appservice.HomeUrl + data
        }
      });

    }
  }

  AddImage(flg) {
    debugger
    var url = this.appservice.HomeUrl + '/api/Farmer/YK_Update_Youtube_Image?Image_Url=' + this.IMG1 + '&CropId=' + 101 + '&flag=' + flg;
    this.http.get(url).subscribe((obj: Response) => {
      if (obj != null) {
        var data = obj.json();
        if (data == 'SUCESS') {
          alert('Your Image Uploaded Successfully');
          this.IMG = "";
          this.IMG1 = "";
          this.id = 101;
          if (flg == 101) {
            this.GetCropDetails(this.cropid);
          }


        }
      }
    })

  }

  confirm2(ID, flg) {
    debugger
    this.confirmationService.confirm({
      message: 'Do you want to delete this Image?',
      header: 'Delete Confirmation',
      icon: 'pi pi-check',
    
      accept: () => {
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'Image deleted' }];
        this.DeleteIt(ID, flg);
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }
  DeleteIt(id,flg) {
    debugger
    var url = this.appservice.HomeUrl + '/api/Farmer/YK_Update_Youtube_Image?Image_Url=""&CropId=' + id + '&flag=' + flg;
    this.http.get(url).subscribe((obj: Response) => {
      if (obj != null) {
        var data = obj.json();
        if (data == 'SUCCESS') {
          alert('Image Deleted Successfully');
          this.id = 101;
          this.IMG = "";
          this.IMG1 = "";
          if (flg ==102) {
            this.GetCropDetails(this.cropid);
          }
         


        }
      }
    })
  }

 


  GetCropDetails(Id) {
    
    this.spinner = true;

    var imagesurl = this.MobServiceUrl + 'api/Farmer/Get_ImageGallery?CropId=' + 101;
    this.http.get(imagesurl).pipe(map(res => res.json())).subscribe((data) => {
      debugger
      if (data != null) {
        this.CropImages = data;
      }
     
      document.getElementById("benefits_usages").click();
    });
  }


 

  image_gallry() {

    $(document).ready(function () {
      $(".fancybox").fancybox({
        openEffect: "none",
        closeEffect: "none"
      });

      $(".zoom").hover(function () {

        $(this).addClass('transition');
      }, function () {

        $(this).removeClass('transition');
      });
    });

  }
  
}
