import { Component } from '@angular/core';
import { map, throttle } from 'rxjs/operators';
import { Http, Headers, RequestOptions } from '@angular/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
    //URL: any = "http://yklaboratories.policymanage.com/webservices/";
  URL: any = "https://www.yklaboratories.com/webservices/";
     // URL: any = "http://localhost:46812/";

  title = 'ClientApp';
  constructor(public http: Http) {
    //this.http.get('../assets/Service.json').pipe(map(res => res.json())).subscribe((res) => {
    //  debugger
    //  var n = res;
    //  this.URL = n.Webservice;
    //  localStorage.setItem("URL", this.URL);
    //});
    localStorage.setItem("URL", this.URL);
  }
  
}
