import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class UserserviceService {
  private user = new BehaviorSubject<string>('0');
  cast = this.user.asObservable();

  private count = new BehaviorSubject<string>('0');
  countitems = this.count.asObservable();
  constructor() {
    var cart = JSON.parse(localStorage.getItem("CartItem"))
    if (cart != null) {
      this.count.next(cart.length);
      this.user.next(cart)

    }
    else {
      this.user.next("0")
    }

  }
  countuser(count) {
    this.count.next(count);
  }
}
