import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { Http, Response } from '@angular/http'
import { map, throttle, debounce } from 'rxjs/operators';
//import { pr} from 'primeng/api';
//import { FilterMatchMode } from 'primeng/api';
//import { SelectItem, FilterService, FilterMatchMode } from "primeng/api";
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  Customers: any;
  Cols: any; count: any; Customers1: any;
  constructor(public appservice: AppService, public http: Http) {
    this.Cols = [
      {
        field: 'CreatedDate', header: 'Created Date', width: '70px' },
      { field: 'FarmerName', header: 'Farmer Name', width: '70px' },
      { field: 'MobileNo', header: 'Mobile No', width: '50px' },
      { field: 'Address', header: 'Address', width: '90px' },
      { field: 'Longitude', header: 'Longitude', width: '50px' },
      { field: 'Lattitude', header: 'Lattitude', width: '90px' },
    ]
  }

  ngOnInit() {
    this.getCustomers();
  }
  getCustomers() {
    debugger;
    this.appservice.getCustomers().subscribe(data => {
      debugger
      if (data != null) {
        this.Customers = data;
        this.Customers1 = this.Customers.filter(a => a.LoginType == '4');
        this.count= this.Customers1.length
      }
    });
  }
}
