import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Http, Headers, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    HomeUrl: string;
    Products: any;
    ProductCategories: any;
    categorizedProducts: any[];
  Crops: any;

  constructor(public http: Http, public router: Router, public appservice: AppService) {
    this.HomeUrl = localStorage.getItem('URL');
   
  }

  ngOnInit() {
    this.getProducts();
    this.getProductCategories();
    this.getCrops();
   
  }
  

  getProductCategories() {
    this.appservice.getCategories().subscribe(data => {
      debugger
      if (data != null) {
        this.ProductCategories = data;
      }
    });
  }

  getProducts() {
    this.appservice.getProducts().subscribe(data => {
      debugger
      if (data != null) {
        this.Products = data;
      }
    });
  }
  getCrops() {
    debugger
    this.appservice.getCrops().subscribe(data => {
      debugger
      if (data != null) {
        this.Crops = data;
      }
    });
  }
}

$(function () {
  $('#carousel-example-generic').on('slide.bs.carousel', function () {
    var iframe = $(this).find('.item.active iframe');
    if (iframe.length > 0) {
      console.log('stop youtube video');
      iframe[0].contentWindow.postMessage('{"event": "command", "func":"stopVideo", "args": ""}', '*');
    }
    // $('.item.active iframe')[0].contentWindow.postMessage('{"event": "command", "func":"pauseVideo", "args": ""}', '*')
  });
});
