import { Injectable } from '@angular/core';
import { Http, Response, ResponseContentType } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, throttle, debounce } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  urlname: any;
  HomeUrl: string;
  crops: string;
  products: string;
  Categories: string;
  Notifications: any;
  notify: string;
  Customers: any;
  Enquiries: any;

  constructor(public http: Http,
    public httpclient: HttpClient) {
    debugger
    this.HomeUrl = localStorage.getItem('URL');
    //this.crops = 'https://yklabsmobileapp.com/yklabswebservices/api/Farmer/GetCropDetails'; 
    this.crops = this.HomeUrl +'api/Farmer/GetCropDetails';
    this.products = this.HomeUrl +'api/Farmer/getCategoryProducts';
    this.Categories = this.HomeUrl + 'api/Farmer/getProductCategories';
    this.notify = this.HomeUrl + 'api/Farmer/GetAllNotifications';
    //this.notify = 'https://yklabsmobileapp.com/yklabswebservices/api/Farmer/GetAllNotifications';
    //this.Customers = 'https://yklabsmobileapp.com/yklabswebservices/api/Farmer/GetFarmerDetails';
    this.Customers = this.HomeUrl + 'api/Farmer/GetFarmerDetails';
    this.Enquiries = this.HomeUrl + 'api/Farmer/getEnquiries';
  }
  getCrops() {
    return this.http.get(this.crops).pipe(map((res: Response) => <any>res.json()));
  }
  getCategories() {
    return this.http.get(this.Categories).pipe(map((res: Response) => <any>res.json()));
  }
  getProducts() {
    return this.http.get(this.products).pipe(map((res: Response) => <any>res.json()));
  }
  getNotifications() {
    return this.http.get(this.notify).pipe(map((res: Response) => <any>res.json()));
  }
  getCustomers() {
    return this.http.get(this.Customers).pipe(map((res: Response) => <any>res.json()));
  }
  getEnquiries() {
    debugger;
    return this.http.get(this.Enquiries).pipe(map((res: Response) => <any>res.json()));
  }



  PostData(url, PostParam) {

    return new Promise((resolve, reject) => {
      this.http.get('../assets/Service.json').subscribe((data: any) => {
        this.urlname = data.Webservice;
        url = this.HomeUrl + url
        this.http.post(url, PostParam).subscribe(data => {

          resolve(data)
        }, err => {
          reject(err)
        })
      }, err => {
        reject(err)
      })
    });
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      navigator.geolocation.getCurrentPosition(resp => {
        if (resp != null) {
          resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });

        }
      },
        err => {
          reject(err);
        });
    });

  }

}
