import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ContactusComponent } from './contactus/contactus.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ProductsComponent } from './products/products.component';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { MatBadgeModule, MatInputModule, MatIconModule } from '@angular/material';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CardModule } from 'primeng/card';

import { ViewCartComponent } from './view-cart/view-cart.component';
import { GalleryComponent } from './gallery/gallery.component';
import { CropGuidanceComponent } from './crop-guidance/crop-guidance.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { AwardsComponent } from './awards/awards.component';
import { OnlineEnquiryComponent } from './online-enquiry/online-enquiry.component';
import { NotificationComponent} from './notification/notification.component'
import { SafePipe } from './SafePipe';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { EditorModule } from 'primeng/editor';
//import { } from 'primeicons'
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CustomersComponent } from './Admin/customers/customers.component';
import { AddproductComponent } from './Admin/addproduct/addproduct.component';
import { TreeTableModule } from 'primeng/treetable';
import { EditInputComponent } from './editinput/editinput.component';
import { DistributorsComponent } from './Admin/distributors/distributors.component';
import { EnquiriesComponent } from './Admin/enquiries/enquiries.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { AccordionModule } from 'primeng/accordion';     //accordion and accordion tab
import { MenuItem } from 'primeng/api';
import { RandDComponent } from './rand-d/rand-d.component';
import { RdnewsComponent } from './rdnews/rdnews.component';
import { RdchilliComponent } from './rdchilli/rdchilli.component';
import { RdpapayaComponent } from './rdpapaya/rdpapaya.component';
import { RnurseryyComponent } from './rnurseryy/rnurseryy.component';
import { HitechnurseryComponent } from './hitechnursery/hitechnursery.component';                  //api
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    ContactusComponent,
    AboutusComponent,
    ProductsComponent,
    ViewCartComponent,
    GalleryComponent, NotificationComponent,
    CropGuidanceComponent,
    TestimonialsComponent,
    AwardsComponent,
    OnlineEnquiryComponent,
    CustomersComponent,
    SafePipe,
    AddproductComponent,
    EditInputComponent,
    DistributorsComponent,
    EnquiriesComponent,
    RandDComponent,
    RdnewsComponent,
    RdchilliComponent,
    RdpapayaComponent,
    RnurseryyComponent,
    HitechnurseryComponent,
  ],
  imports: [
    BrowserModule, TableModule, ConfirmDialogModule,   AppRoutingModule, BrowserAnimationsModule, HttpModule, HttpClientModule, MatBadgeModule, FormsModule,
    ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatIconModule, ProgressSpinnerModule, TreeTableModule,
    ToastModule, EditorModule, AutoCompleteModule, CardModule
  ],
  providers: [MessageService],
  bootstrap: [AppComponent],
})
export class AppModule { }
