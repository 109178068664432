import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-edit-input',
  templateUrl: './editinput.component.html',
  styleUrls: ['./editinput.component.scss'],
})
export class EditInputComponent implements OnInit {
  @Input() data: any;
  @Output() focusOut: EventEmitter<any> = new EventEmitter<any>();
 
  editMode = true;
  constructor() { }

  ngOnInit() { }

  onFocusOut() {
    this.focusOut.emit(this.data);
  }
}
