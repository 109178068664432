import { Component, OnInit } from '@angular/core';
import { UserserviceService } from '../userservice.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-view-cart',
  templateUrl: './view-cart.component.html',
  styleUrls: ['./view-cart.component.scss']
})
export class ViewCartComponent implements OnInit {
  Productdetails: any;
  price: any;
  CartTotalPrice: any;
  qty: any;
  TotalPrice: any;
  LoginDetails: any;
  HomeURL: any;
  OrderNO: any;
  City: any;
  Address: any;
  Pincode: any;
  Area: any;
  LandMark: any;

  constructor(public userservice: UserserviceService,
    public httpclient: HttpClient,
    public messageservice: MessageService)
    {
    debugger
    this.HomeURL = localStorage.getItem("URL");
    this.LoginDetails = JSON.parse(sessionStorage.getItem("LoginDetails"));
    this.Productdetails = JSON.parse(localStorage.getItem("CartItem"))
    if (this.Productdetails == 0 || this.Productdetails == null) {

      this.Productdetails = 0;
      this.price = 0;
    }
    else {
      debugger
      this.CartTotalPrice = JSON.parse(localStorage.getItem("TotalPrice"));
    }
    this.GetTotal()
  }

  ngOnInit() {
  }
  incrementQty(menu) {
    debugger
    this.qty = menu.ItemQuantity
    this.qty = this.qty + 1;
    let index = this.Productdetails.indexOf(menu);
    this.Productdetails[index].ItemQuantity = this.qty;
    this.Productdetails[index].ItemTotalPrice = (menu.ItemTotalPrice / (this.qty - 1)) * this.qty;
    this.GetTotal()
    localStorage.setItem("CartItem", JSON.stringify(this.Productdetails));
  }

  decrementQty(menu) {
    debugger
    this.qty = menu.ItemQuantity
    if (this.qty == 1) {
    }
    else {
      this.qty = this.qty - 1;
      let index = this.Productdetails.indexOf(menu);
      this.Productdetails[index].ItemQuantity = this.qty;
      this.Productdetails[index].ItemTotalPrice = (menu.ItemTotalPrice / (this.qty + 1)) * this.qty;
      this.GetTotal()
      localStorage.setItem("CartItem", JSON.stringify(this.Productdetails));
    }
  }
  GetTotal() {
    debugger
    this.CartTotalPrice = 0;
    if (this.Productdetails != null) {
      for (var i = 0; i < this.Productdetails.length; i++) {
        this.CartTotalPrice = this.CartTotalPrice + ((this.Productdetails[i].ItemTotalPrice / (this.Productdetails[i].ItemQuantity)) * this.Productdetails[i].ItemQuantity);
        localStorage.setItem("TotalPrice", JSON.stringify(this.CartTotalPrice));
      }
      this.price = this.CartTotalPrice;
      this.CartTotalPrice = this.CartTotalPrice;
    }
  }

  RemoveItems(i: any, value: any) {
    debugger
    this.Productdetails = JSON.parse(localStorage.getItem("CartItem"))
    this.Productdetails.splice(i, 1);
    localStorage.setItem("CartItem", JSON.stringify(this.Productdetails));
    this.Productdetails = JSON.parse(localStorage.getItem("CartItem"))
    this.userservice.countuser(this.Productdetails.length);
    this.CartTotalPrice = 0
    if (this.Productdetails.length != 0) {
      for (var j = 0; j < this.Productdetails.length; j++) {
        var price = this.Productdetails[j].ItemTotalPrice
        this.CartTotalPrice = this.CartTotalPrice + price
      }
    }
    this.TotalPrice = this.CartTotalPrice;
    if (this.CartTotalPrice != 0) {

      this.CartTotalPrice = this.CartTotalPrice;
      this.GetTotal();
    }
  }
  AddressPopUp() {
    debugger
    document.getElementById('Addresspopup').click();
  }
  SubmitOrder(City: any, Address: any, Pincode: any,Area:any,LandMark:any) {
    this.LoginDetails = JSON.parse(sessionStorage.getItem("LoginDetails"));
    if (City == "" || City == undefined || City == null ||
      Pincode == "" || Pincode == null || Pincode == undefined
      || Address == "" || Address == null || Address == undefined) {
      this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'All fiels are manadatory..!!' });
    }
    else if (this.LoginDetails == null) {
      this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Please Login/Register to place order..!!' });
      document.getElementById('Addresspopup').click();
    }
    else if (this.LoginDetails != null) {
      //var date = new Date()
      //var TransactionId = date.getTime();
      debugger
      let Array = [];
      Array.push({
          RegID: this.LoginDetails.Id,
          SizeName: this.Productdetails[0].Pack,
          ProductId: this.Productdetails[0].Productid,
          Quantity: this.Productdetails[0].ItemQuantity,
          TotalProducts:'1',
          TotalAmount:'0.0',
          AdminAmount: '0.0',
          PricePerQuantity:'0.0',
          Status: '1',
          City: City,
          Area: Area,
          LandMark: LandMark,
          Address: Address,
          Pincode: Pincode
      })
      //this.Productdetails[0].GrandTotal = this.CartTotalPrice;
      //this.Productdetails[0].FarmerId = this.LoginDetails.Id;
      //this.Productdetails[0].City = City;
      //this.Productdetails[0].Address = Address;
      //this.Productdetails[0].Pincode = Pincode;
      //this.Productdetails[0].CreatedBy = this.LoginDetails.FarmerName;
      var UploadFile = new FormData();
      UploadFile.append("addenquiryXML", JSON.stringify(Array));
      var Url = this.HomeURL + 'api/Farmer/Addenquiry';
      this.httpclient.post(Url, UploadFile).subscribe((data:any) => {
        debugger
        //this.OrderNO = data;
        if (data.MSG == 'SUCCESS'){
          this.messageservice.add({ severity: 'success', summary: 'SUCCESS', detail: 'Enquiry Placed  Successfully ' });
           this.City = "";
           this.Address = "";
           this.Pincode = "";
           this.Area = "";
           this.LandMark = "";
          var Url = 'api/Farmer/mailforuserplaceorder';
          var UploadFile = new FormData();
          Array[0].EnquiryNumber=data.MSG1
          UploadFile.append("OrderDetails", JSON.stringify(Array));
          this.httpclient.post(Url, UploadFile).subscribe(data => {
            debugger

          });
        }
        else {
          this.messageservice.add({ key: 'tr1', severity: 'error', summary: 'Error', detail: 'something went wrong' });
        }
        //var PaymentDetails = this.HomeURL + '/api/Farmer/YkLabs_Payment?Name=' + this.LoginDetails.FarmerName +
        //  "&Amont=" + this.CartTotalPrice + "&Email=" + "shekarbabu617@gagriglobal.com" + "&Productinfo=" + "YKLABS" +
        //  "&Mobile=" + this.LoginDetails.MobileNo + "&TransactionId=" + this.OrderNO;
        //this.httpclient.get(PaymentDetails).subscribe(data => {
        //  debugger
        //  window.location.href = data.toString();
        //})
      })
    }
  }
}
