import { Component, OnInit } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map, throttle, window } from 'rxjs/operators';
import { UserserviceService } from '../userservice.service';
import { AppService } from '../app.service';
import { MessageService } from 'primeng/api';
declare var $: any;
@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  Name: any;
  Subject: any;
  Email: any;
  Mobile: any;
  Message: any;
  Homeurl: any;
  AlertBox: any;
  Alertmessage: any;
  Alertmessage1: any;
  message: boolean = false;
  constructor(public router: Router, public http: Http,
    public route: ActivatedRoute,
    public appservice: AppService,
    public messageservice: MessageService,
    public userservice: UserserviceService)
  {
    this.Homeurl = localStorage.getItem('URL');
  }
  
  ngOnInit() {
  }

  Send(Name, Email, Subject, Message, Mobile  ) {
    debugger
    if (Name == "" || Name == null || Name == undefined ||
      Email == "" || Email == null || Email == undefined ||
      Subject == "" || Subject == null || Subject == undefined ||
      Mobile == "" || Mobile == null || Mobile == undefined ||
      Message == "" || Message == null || Message == undefined) {
     this.AlertBox = true;
    this.Alertmessage = "All * fields are mandetory"
    }
    else {
      debugger
      let details = [];
      details.push({ Name: Name, Email: Email, Subject: Subject, Mobile: Mobile, Message: Message  });
      debugger
      var path = this.Homeurl + '/api/Farmer/ContactUs?details=' + JSON.stringify(details);
      this.http.get(path).pipe(map(res => res.json())).subscribe((object: any) => {
        if (object == "SUCCESS") {
          this.AlertBox = true;
          this.message = true;
          setTimeout(() => {
            this.message = false;
          }, 3000);
         // this.Alertmessage1 = "Contact Details sent Successfully............!"
          this.Name = "";
          this.Email = "";
          this.Subject = "";
          this.Mobile = "";
          this.Message = "";
        }

      });
    }

  }














}
