import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { Http, Response } from '@angular/http'
import { map, throttle, debounce } from 'rxjs/operators';
import { zip } from 'rxjs';
declare var $;
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-enquiries',
  templateUrl: './enquiries.component.html',
  styleUrls: ['./enquiries.component.css']
})
export class EnquiriesComponent implements OnInit {
  cols: any;
  Enquiries: any;
  count: any;
  StatusId: any = 1;
  DistributorId: any;
  Distributors: any;
  Distributors1: any;
  DistId: any;
  Id: any;
  Homeurl: string;
  AlertBox: any;
  Alertmessage: any;
  Enquiries1: any;
    EnquiryDetails: ObjectConstructor;
 
  constructor(public appservice: AppService, public http: Http, public messageservice: MessageService) {
   
    this.cols = [
      { field: 'CreatedDate', header: 'Created Date', width: '200px' },
      { field: 'EnquiryNumber', header: 'Enquiry Number', width: '160px' },
      { field: 'FarmerName', header: 'Farmer Name', width: '150px' },
      { field: 'MobileNo', header: 'Mobile', width: '150px' },
      { field: 'TotalAmount', header: 'Total Amount', width: '150px' },
      { field: 'Address', header: 'Address', width: '120px' },
      { field: 'Area', header: 'Area', width: '120px' },
      { field: 'City', header: 'City', width: '120px' },
      { field: 'Pincode', header: 'Pincode', width: '120px' },
      { field: 'Landmark', header: 'Landmark', width: '150px' },     
    ]
    this.Homeurl = localStorage.getItem('URL');
  }

  ngOnInit() {
    $(document).ready(function () {
      $('#myModal').modal('hide');
    });  
    this.getEnquiries();
    this.getDistributors(); 
  }
  FilterEnquiries(id) {
    debugger;
    this.StatusId = id;
    if (this.StatusId == 1) {
      this.cols = [
        { field: 'CreatedDate', header: 'Created Date', width: '200px' },
        { field: 'EnquiryNumber', header: 'Enquiry Number', width: '160px' },
        { field: 'FarmerName', header: 'Farmer Name', width: '150px' },
        { field: 'MobileNo', header: 'Mobile', width: '150px' },
        { field: 'TotalAmount', header: 'Total Amount', width: '150px' },
        { field: 'Address', header: 'Address', width: '120px' },
        { field: 'Area', header: 'Area', width: '120px' },
        { field: 'City', header: 'City', width: '120px' },
        { field: 'Pincode', header: 'Pincode', width: '120px' },
        { field: 'Landmark', header: 'Landmark', width: '150px' },
      ]
    }
    else {
      this.cols = [
        { field: 'CreatedDate', header: 'Created Date', width: '200px' },
        { field: 'EnquiryNumber', header: 'Enquiry Number', width: '160px' },
        { field: 'DistibutorName', header: 'Distibutor Name', width: '160px' },
        { field: 'FarmerName', header: 'Farmer Name', width: '150px' },
        { field: 'MobileNo', header: 'Mobile', width: '150px' },
        { field: 'TotalAmount', header: 'Total Amount', width: '150px' },
        { field: 'Address', header: 'Address', width: '120px' },
        { field: 'Area', header: 'Area', width: '120px' },
        { field: 'City', header: 'City', width: '120px' },
        { field: 'Pincode', header: 'Pincode', width: '120px' },
        { field: 'Landmark', header: 'Landmark', width: '150px' },
      ]
    }
    this.getEnquiries();
  }
  getEnquiries() {
    debugger
    this.appservice.getEnquiries().subscribe(data => {
      debugger
      if (data != null) {
        this.Enquiries1 = data;
        this.Enquiries = this.Enquiries1.filter(a => a.Status == this.StatusId);     
        this.count = this.Enquiries.length;      
      }
    });
  }
  SendNtifications() {
    debugger
    var Url = this.Homeurl+'api/Farmer/SendNotificationToDistributors/' + this.DistId + '?Text=' + 'New enquiry assigned please check it';
    this.http.get(Url).pipe(map(res => res.json())).subscribe((object: any) => {
      debugger
      if (object == "SUCCESS") {
        
      }
    });
  }
  Submit(Id) {
    debugger;
    this.StatusId = 2;
    this.DistId= Id;
    $('#myModal').modal('hide');
    var path = this.Homeurl + 'api/Farmer/AssignDistributor/' + this.Id + '?DistributorId=' + this.DistId + '&Status=' + '2';
    this.http.get(path).pipe(map(res => res.json())).subscribe((object: any) => {
      debugger
      if (object == "SUCCESS") {
        this.getEnquiries();
        this.messageservice.add({ key: 'tr', severity: 'success', summary: 'SUCCESS', detail: 'Enquiry assign to distributor successfully' });
        this.SendNtifications();
      }
    });
  }
  Assign(rowData) {
    debugger
    this.Id = rowData.EnquiryId;
    $('#myModal').modal('show');
  }
  getDistributors() {
    debugger;
    this.appservice.getCustomers().subscribe(data => {
      debugger
      if (data != null) {
        this.Distributors = data;
        this.Distributors1 = this.Distributors.filter(a => a.LoginType == '5');
      }
    });
  }
  ViewEnquiryDetails(rowData) {
    debugger
    $('#myModal1').modal('show');
    var path = this.Homeurl + "api/Farmer/GetProductsBasedOnEnquiryId?EnquiryId=" + rowData.EnquiryId;  
    this.http.get(path).pipe(map(res => res.json())).subscribe((Object: any) => {
      debugger
      this.EnquiryDetails = Object;
    });
  }
}
