import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
//import { DomSanitizer } from '@angular/platform-browser';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  HomeUrl: any;
  Details: any;
  youtubeurl: any;
  constructor(public http: HttpClient,  private _sanitizer: DomSanitizer) {
    debugger
    this.HomeUrl = localStorage.getItem('URL');
  }

  ngOnInit() {
    debugger
    this.Getallyoutubevideos();
  }


  GetYoutubeUrl(url) {
    debugger
    return this._sanitizer.bypassSecurityTrustResourceUrl(url);

  }



  Getallyoutubevideos() {
    debugger
    var path = this.HomeUrl + '/api/Farmer/GetAllYoutubevideogallery';
    this.http.get(path).pipe(map(res => res)).subscribe((object: any) => {
      debugger
      if (object.length > 0) {
        this.Details = object;
        debugger
        this.youtubeurl = this._sanitizer.bypassSecurityTrustResourceUrl(this.Details[0].YoutubeLink);
      }
      else {

      }
    });
  }
}
