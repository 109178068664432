import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { UserserviceService } from '../userservice.service';
import { FormBuilder, FormGroup, FormControl, Validators, MinLengthValidator } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { map, min} from 'rxjs/operators';
import { AppService } from '../app.service';
import { MessageService } from 'primeng/api';
import { Http } from '@angular/http'
declare var $: any;
declare var window: any;
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  styles: [`
        :host ::ng-deep button {
            margin-right: .25em;
        }

        :host ::ng-deep .custom-toast .ui-toast-message {
            color: #ffffff;
            background: #FC466B;
            background: -webkit-linear-gradient(to right, #3F5EFB, #FC466B);
            background: linear-gradient(to right, #3F5EFB, #FC466B);
        }

        :host ::ng-deep .custom-toast .ui-toast-close-icon {
            color: #ffffff;
        }
    `],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class HeaderComponent implements OnInit {
  multiTg: any;
  resizeFix: any;
  count: any;
  public RegistrationForm: FormGroup;
  public OTPForm: FormGroup;
  HomeURL: any;
  OTP: any;
  Mobile: any;
  Password: any;
  ConfirmPassword: any;
  Products: any;
  LoginDetails: any;
  hide = true; msg: any;
  categorizedProducts: any;
  ProductCategories: any;
  Crops: any;
  ForgetPwdrandomNumber: any;
  RegrandomNumber: any;
  CurrentIndex: any;
  openElements: boolean[] = [];
  DataToggle: boolean = false;
  Producttoggle: boolean = false;
  Croptoggle: boolean = false;
  type = '1';
    LocDet: any;
    Longitude: any;
  Lattitude: any;
  Exist: boolean = false;
  constructor(public router: Router, private http: Http,
    public userservice: UserserviceService,
    public formBuilder: FormBuilder,
    public appservice: AppService,
    public httpclient: HttpClient,
    public messageservice: MessageService) {
    this.userservice.countitems.subscribe(count => this.count = count);
    
    this.HomeURL = localStorage.getItem("URL");
    this.LoginDetails = JSON.parse(sessionStorage.getItem("LoginDetails"));
    if (this.LoginDetails != null) {
      this.type = this.LoginDetails.LoginType;
    }
  }

  ngOnInit() {
    // this.scrolltop();
    this.loc1();
this.respon();
this.navbar();
    this.chat();
    
    this.getCrops();
    debugger;
    this.getProductCategories();
    this.getProducts();
    this.RegistrationForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Mobile: ['', Validators.compose([Validators.maxLength(10), Validators.minLength(10), Validators.pattern('[0-9]*'), Validators.required])],
      Password: ['', Validators.required],
      ConfirmPassword: ['', Validators.required]
    })
    this.OTPForm = this.formBuilder.group({
      OTP: ['', Validators.compose([Validators.maxLength(4), Validators.minLength(4), Validators.pattern('[0-9]*'), Validators.required])],
    })
    //this.Mobile = this.RegistrationForm.controls.Mobile.value; //Not using

  }


  loc1() {
    ;


    this.appservice.getPosition().then(pos => {
      
      this.Longitude = pos.lng;
      this.Lattitude = pos.lat; 
    });
    //var GetUrl = 'http://api.ipstack.com/check?access_key=83ef7a5415d0c9522944fa09208ac349';

    //this.http.get(GetUrl).pipe(map(res => res.json())).subscribe((response:any) => {

    ////this.http.get(GetUrl).subscribe((response: any) => {
    // 
    //  this.LocDet = response;
    //  this.Longitude = response.longitude;
    //  this.Lattitude = response.latitude; 
      
    //});

  }



  chat() {
    var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"), s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/5cf905f9267b2e57853125fc/default';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      s0.parentNode.insertBefore(s1, s0);
    })();
  }

respon(){
$(document).ready(function () {

    $('.navbar .dropdown-item').on('click', function (e) {
        var $el = $(this).children('.dropdown-toggle');
        var $parent = $el.offsetParent(".dropdown-menu");
        $(this).parent("li").toggleClass('open');

        if (!$parent.parent().hasClass('navbar-nav')) {
            if ($parent.hasClass('show')) {
                $parent.removeClass('show');
                $el.next().removeClass('show');
                $el.next().css({"top": -999, "left": -999});
            } else {
                $parent.parent().find('.show').removeClass('show');
                $parent.addClass('show');
                $el.next().addClass('show');
                $el.next().css({"top": $el[0].offsetTop, "left": $parent.outerWidth() - 4});
            }
            e.preventDefault();
            e.stopPropagation();
        }
    });

    $('.navbar .dropdown').on('hidden.bs.dropdown', function () {
        $(this).find('li.dropdown').removeClass('show open');
        $(this).find('ul.dropdown-menu').removeClass('show open');
    });

});

}


  navbar() {
    (function ($) {
      $.fn.menumaker = function (options) {
        var cssmenu = $(this), settings = $.extend({
          format: "dropdown",
          sticky: false
        }, options);
        return this.each(function () {
          $(this).find(".button").on('click', function () {
            $(this).toggleClass('menu-opened');
            var mainmenu = $(this).next('ul');
            if (mainmenu.hasClass('open')) {
              mainmenu.slideToggle().removeClass('open');
            }
            else {
              mainmenu.slideToggle().addClass('open');
              if (settings.format === "dropdown") {
                mainmenu.find('ul').show();
              }
            }
          });
          //cssmenu.find('li ul').parent().addClass('has-sub');
          //if (settings.format === 'multitoggle')multiTg();
          //else cssmenu.addClass('dropdown');
          //if (settings.sticky === true) cssmenu.css('position', 'fixed');
        });
      };
    })
  }

  sendNotify() {
    $('#myModal21').modal('show');
  }
 
  LoginOrRegister() {
    
    document.getElementById('LoginorRegister').click();
  }
  RegistrationPopUp() {
    
    document.getElementById('openModalButton').click();
  }
  ForPwdOpenPopUp() {
    document.getElementById('Forgetpwd').click();
  }
  CheckMobile(value) {
    debugger
    let Mobilecheck = this.HomeURL + '/api/Farmer/FarmerRegistration_OTP?MobileNo=' + value.Mobile;
    this.httpclient.get(Mobilecheck).subscribe(data => {
      if (data == 'MOBILE EXIST') {
        
        this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Mobile No already exists..!!' });
        this.RegistrationForm.controls.Mobile.setValue("");
      }
    })
  }

  //////////////////////////////   Login,Register and Forget Password Functions    //////////////////////////////////
  Login(Mobile: any, Password: any) {
    
    if (Mobile == null || Mobile == undefined || Mobile == "" || Password == null || Password == undefined || Password == "") {
      this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter Mobileno and passoword..!!' });
    }
    else {
      var Logindetails = this.HomeURL + '/api/Farmer/Login?UserName=' + Mobile + '&Password=' + Password;
      this.httpclient.get(Logindetails).subscribe(data => {
        if (data == 'NOT EXIST') {
          this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Mobile or password is incorrect' });
        }
        else {
          
          document.getElementById('LoginorRegister').click();
          this.LoginDetails = JSON.stringify(data[0]);
          sessionStorage.setItem('LoginDetails', this.LoginDetails);
          location.reload();
        }
      },
        err => {
          this.messageservice.add({ key: 'tr1', severity: 'error', summary: 'Error', detail: 'something went wrong' });

        }
      )
    }
  }

  submitOTP(value) {
    if (value.Mobile == "" || value.Mobile == undefined || value.Password == "" || value.Password == undefined ||
      value.ConfirmPassword == "" || value.ConfirmPassword == undefined) {
      this.messageservice.add({ key: 'tr1', severity: 'error', summary: 'Error', detail: 'All fields are mandatory' });
    }
    else if (value.Password != value.ConfirmPassword) {
      this.messageservice.add({ severity: 'error', summary: 'Error', detail: 'Password and ConfirmPassword must be same' });
    }
    else {
      
      this.RegrandomNumber = Math.floor(1000 + Math.random() * 9000);
      var Message ='Your OTP  with  YK Labs is:' +this.RegrandomNumber
      var OTPDetails = this.HomeURL + '/api/Farmer/YKLABS_Send_SMS?MobileNo=' + value.Mobile + "&Message=" + Message;
      this.httpclient.get(OTPDetails).subscribe((data: any) => {
        
        if (data == "SUCCESS") {
          
          this.messageservice.add({ key: 'tr2', severity: 'info', summary: 'Info msg', detail: 'Please enter OTP' });
          document.getElementById('openModalButton').click();
          document.getElementById('OTPPopUp').click();
          document.getElementById('LoginorRegister').click();
        }
        else {
          err => {
            this.messageservice.add({ key: 'tr1', severity: 'error', summary: 'Error', detail: 'something went wrong' });
          }
        }
      })
    }
  }

  submitFarmerRegForm(value: any) {
    
    if (this.RegrandomNumber == value.OTP) {
      let RegDetails = [];
      RegDetails.push({
        FarmerName: this.RegistrationForm.controls.Name.value,
        MobileNo: this.RegistrationForm.controls.Mobile.value,
        Password: this.RegistrationForm.controls.Password.value,
        Status: '1',
        LoginType: '4',
        Lattitude:this.Lattitude,
        Longitude:this.Longitude,

      });
      var SaveRegDetails = this.HomeURL + '/api/Farmer/AddFarmarData?AddfarmerXML=' + JSON.stringify(RegDetails);
      this.httpclient.get(SaveRegDetails).subscribe(data => {
        
        if (data == "SUCCESS") {
          
          this.messageservice.add({ key: 'tr', severity: 'success', summary: 'SUCCESS', detail: 'You have registered successfully...!' });
          document.getElementById('OTPPopUp').click();
          document.getElementById('LoginorRegister').click();
          this.Login(this.RegistrationForm.controls.Mobile.value, this.RegistrationForm.controls.Password.value);
        }
        else {
          this.messageservice.add({ key: 'tr1', severity: 'error', summary: 'Error', detail: 'Mobile Exist' });
        }
      })
    }
    else {
      this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Enter valid OTP' });

    }
  }

  ForgetPwd(Mobile) {
    
    let Mobilecheck = this.HomeURL + '/api/Farmer/FarmerRegistration_OTP?MobileNo=' + Mobile;
    this.httpclient.get(Mobilecheck).subscribe(data => {
      if (data == 'MOBILE EXIST') {
        
        this.ForgetPwdrandomNumber = Math.floor(1000 + Math.random() * 9000);
        var OTPDetails = this.HomeURL + '/api/Farmer/YKLABS_Send_SMS?MobileNo=' + Mobile + "&Message=" + this.ForgetPwdrandomNumber;
        this.httpclient.get(OTPDetails).subscribe((data: any) => {
          if (data == "SUCCESS") {
            document.getElementById('forgetpwdpopup').click();
          }
          else {
            err => {
              this.messageservice.add({ key: 'tr1', severity: 'error', summary: 'Error', detail: 'something went wrong' });
            }
          }
        })
      }
      else if (data == 'NOTEXIST') {
        this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Mobile does not exists..!!' });
      }
      else {
        this.messageservice.add({ key: 'tr1', severity: 'error', summary: 'Error', detail: 'something went wrong' });
      }
    })
  }

  MobieVerification(value: any) {
    
    if (this.ForgetPwdrandomNumber == value.OTP) {
      
      this.messageservice.add({ key: 'tr', severity: 'success', summary: 'SUCCESS', detail: 'MobileNo Verified' });
      document.getElementById('UpdatePassword').click();
    }
    else {
      this.messageservice.add({ key: 'tr1', severity: 'error', summary: 'Error', detail: 'Enter valid OTP' });
    }
  }

  UpdatePassword(Password, ConfirmPassword) {
    
    if (Password == undefined || Password == null || ConfirmPassword == undefined || ConfirmPassword == null) {
      this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Please enter all details' });
    }
    else if (Password != ConfirmPassword) {
      this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Password and Confirm password must be same' });
    }
    else {
      let UpdatePassword = this.HomeURL + '/api/Farmer/YK_changePassword?Password=' + Password + "&MobileNo=" + this.Mobile;
      this.httpclient.get(UpdatePassword).subscribe(data => {
        
        if (data == 'SUCCESS') {
          this.messageservice.add({ key: 'tr', severity: 'success', summary: 'SUCCESS', detail: 'Passsword updated successfully...!' });
          document.getElementById('UpdatePassword').click();
          document.getElementById('forgetpwdpopup').click();
          document.getElementById('LoginorRegister').click();
          document.getElementById('Forgetpwd').click();
          
          this.Login(this.Mobile, this.Password);
        }
        else {
          this.messageservice.add({ key: 'tr1', severity: 'error', summary: 'Error', detail: 'something went wrong' });

        }
      })
    }
  }


                ////////////////////////////////////// END //////////////////////////////////////////////////////

  Logout() {
    
    sessionStorage.removeItem("LoginDetails");
    
    this.router.navigateByUrl('/home', { skipLocationChange: false }).then(() => {
      
      this.router.navigate(['/home']);
      location.reload();
    });
   // this.router.navigateByUrl('/home');
    //this.router.navigateByUrl('/home');
    //location.reload();
    //this.router.navigateByUrl('/home');

  }

  getProductCategories() {
    this.appservice.getCategories().subscribe(data => {
      
      if (data != null) {
        this.ProductCategories = data;
        //for (let i = 0; i < this.ProductCategories.length; i++) {
        //  var j = []
        //  var k = this.ProductCategories[i].CategoryId
        //  this.ProductCategories[i].Showdetails = false
        //  var m = this.Products
        //  j = m.filter(a => a.CategoryId ===k );
        //  if (j.length > 0) {
        //    this.ProductCategories[i].navigationItems = j
        //  }
        //}
        //console.log(this.ProductCategories)
      }
    });
  }
  dataToggle() {
    this.DataToggle = !this.DataToggle
    this.Producttoggle = false
    this.Croptoggle = false;
    for (let j = 0; j < this.ProductCategories.length; j++) {
      this.ProductCategories[j].Showdetails = false
    }
  }
  open(i) {
    
    for (let j = 0; j < this.ProductCategories.length; j++) {
      this.ProductCategories[j].Showdetails = false
    }
    if (this.CurrentIndex == i) {
      this.ProductCategories[i].Showdetails = false
    }
    else {
      this.filterProducts(i)
      this.ProductCategories[i].Showdetails = true
    }
    this.CurrentIndex=i
  }
  getProducts() {
    this.appservice.getProducts().subscribe(data => {
      
      if (data != null) {
        this.Products = data;
        
      }
    });
  }
  ProductToggle() {
    
    this.Producttoggle = !this.Producttoggle
  }
  CropToggle() {
    this.Croptoggle = !this.Croptoggle
  }

  filterProducts(i) {
    
    var Categoryid=0
    this.CurrentIndex = i
    Categoryid = this.ProductCategories[i].CategoryId
    this.categorizedProducts = [];
    this.categorizedProducts = this.Products.filter(a => a.CategoryId == Categoryid);
  }

  getCrops() {
    this.appservice.getCrops().subscribe(data => {
      
      if (data != null) {
        
        this.Crops = data;
      }
    });
  }

  toggle(Exist) {

    debugger

    this.Exist = Exist;

  }

}
