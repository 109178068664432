import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rdchilli',
  templateUrl: './rdchilli.component.html',
  styleUrls: ['./rdchilli.component.css']
})
export class RdchilliComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
