import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AppService } from '../../app.service';
import { Http, Response } from '@angular/http'
import { map, throttle, debounce } from 'rxjs/operators';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css']
})
export class AddproductComponent implements OnInit {
  Imagelist: any;
  DocumentName: any;
  fileList: any;
  Cats: any;
  name: any;
  qty: any;
  subject: any;
  desc: any;
  arr: any = [];
  imagefile: any;
  ProductImage: any;
  image: any;
  ImageArray: any = [];
  ImageData: any;
  public steps: any[];
  productname: any;
  CategoryName: any;
  Image: any;
  ProductInfo: any;
  Benfits: any;
  RecommendedCrops: any;
  Dosage: any;
  Ingredients: any;
  public ProductForm: FormGroup;
  public BenfitsForm: FormGroup;
  public CropsForm: FormGroup;
  public DosageForm: FormGroup;
  public IngredientsForm: FormGroup;
  Pack1: any;
  Price1: any;
  Pack2: any;
  Price2: any;
  Pack3: any;
  Price3: any;
  Pack4: any;
  Price4: any;


  
  public details: any = [];
  public showConfirm: boolean;
  public confirmed: boolean;

  public ckeditorContent: string;
  public config: any;
  selectedCat: any[];
  filteredCats: any[];

  constructor(public formBuilder: FormBuilder, public messageservice: MessageService,  public appservice: AppService, public http: Http) {
    //this.ProductForm = this.formBuilder.group({
    //  CategoryName: ['', Validators.required],
    //  productname: ['', Validators.required],
    //  Pack1 : ['', ],
    //  Price1: ['', ],
    //  Pack2 : ['', ],
    //  Price2: ['', ],
    //  Pack3 : ['', ],
    //  Price3: ['', ],
    //  Pack4 : ['', ],
    //  Price4: ['', ],
    //  ProductInfo: [''],
    //});

    let password = new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)]));
    let confirmPassword = new FormControl('', Validators.compose([Validators.required]));

    this.steps = [
      { name: 'Product', icon: 'fa-lock', active: true, valid: false, hasError: false },
      { name: 'Benefits', icon: 'fa-user', active: false, valid: false, hasError: false },
      { name: 'Crops', icon: 'fa-credit-card', active: false, valid: false, hasError: false },
      { name: 'Dosage', icon: 'fa-credit-card', active: false, valid: false, hasError: false },
      { name: 'Ingredients', icon: 'fa-credit-card', active: false, valid: false, hasError: false },

     // { name: 'Confirm Your Details', icon: 'fa-check-square-o', active: false, valid: false, hasError: false }
    ]
    
    this.ProductForm = this.formBuilder.group({
      CategoryName: ['', Validators.required],
      productname    : ['', Validators.required],
      Pack1          : ['', ],
      Price1         : ['', ],
      Pack2           : ['', ],
      Price2         : ['', ],
      Pack3          : ['', ],
      Price3         : ['', ],
      Pack4          : ['', ],
      Price4         : ['', ],
      ProductInfo: ['', Validators.required],
      Image: ['']
    });

    this.BenfitsForm = this.formBuilder.group({
    
      'Benfits': ['', Validators.required],
     
    });

    this.CropsForm = this.formBuilder.group({
      'RecommendedCrops': ['', Validators.required],
     
    });
    this.DosageForm = this.formBuilder.group({
      'Dosage': ['', Validators.required],

    });
    this.IngredientsForm = this.formBuilder.group({
      'Ingredients': ['', Validators.required],

    });  


    this.ckeditorContent = '<div>Hey we are testing CKEditor</div>';
    this.config = {
      uiColor: '#F0F3F4',
      height: '350',
      extraPlugins: 'divarea'
    };

  }

  ngOnInit() {
    this.getCategories();
  }


  urls = new Array<string>();
  urls1 = new Array<string>();

  /*remove the multiple images*/
  removeImage(index): void {    /////Remove Image
    debugger
    this.urls.splice(index, 1);
    this.ImageArray.splice(index, 1);
  }
  /*end code*/

  detectFiles(event) {
    debugger
    //this.urls = [];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {

          this.urls.push(e.target.result);

        }
        reader.readAsDataURL(file);
      }
      this.fileChange(event);
    }

  }
  fileChange(event) {
    debugger
    //let fileList = event.target.files;
    let fileList = event.target.files;

    if (fileList.length > 0) {
      const frmData = new FormData();
      //for (var i = 0; i < fileList.length; i++) {
        frmData.append("PostedFile", fileList[0]);
      
      var url = this.appservice.HomeUrl+ 'api/Farmer/Product_Images';
      this.http.post(url, frmData).subscribe((obj: Response ) => {
     // this.appservice.PostData(url, frmData).subscribe((data: Response) => {
        debugger
        var data = obj.json();
        if (data != null) {
          debugger
          this.ImageArray = [];
          this.ImageData = data;
          this.ImageArray.push({ Image:this.ImageData });
        }
        });
      //}
    }
  }

















  AddProducts(val) {
    debugger
    this.arr = [];
    this.arr.push({
      ProductName: val.name,
      Quantity: val.qty,
      Description: val.desc,
      Images: this.ImageArray
    })
    var url = this.appservice.HomeUrl+"api/Farmer/AddProducts?AddProductXML=" + JSON.stringify(this.arr);
    this.http.get(url).subscribe((obj: Response) => {
      // this.appservice.PostData(url, frmData).subscribe((data: Response) => {
      debugger
      var data = obj.json();
      if (data == "SUCCESS")
      {
        alert("Product added successfully");
      }
      else {
        alert("failed");
      }
    });

  }
  Back() {
    debugger

  }
  //=====================================================//


  getCategories() {
    debugger
    this.appservice.getCategories().subscribe(data => {
      debugger
      if (data != null) {
        this.Cats = data;
      }
    });
  }

  public next() {
    let ProductForm = this.ProductForm;
    let BenfitsForm = this.BenfitsForm;
    let CropsForm = this.CropsForm;
    let DosageForm = this.DosageForm;
    let IngredientsForm = this.IngredientsForm;


    debugger
    if (this.steps[this.steps.length - 1].active)
      return false;
    debugger
    this.steps.some(function (step, index, steps) {
      debugger
      if (index < steps.length - 1) {
        debugger
        if (step.active) {
          debugger
          if (step.name == 'Product') {
            if (ProductForm.valid) {
              step.active = false;
              step.valid = true;
              steps[index + 1].active = true;
              return true;
            }
            else {
              step.hasError = true;
            }
          }
          if (step.name == 'Benefits') {
            if (BenfitsForm.valid) {
              step.active = false;
              step.valid = true;
              steps[index + 1].active = true;
              return true;
            }
            else {
              step.hasError = true;
            }
          }
          if (step.name == 'Crops') {
            if (CropsForm.valid) {
              step.active = false;
              step.valid = true;
              steps[index + 1].active = true;
              return true;
            }
            else {
              step.hasError = true;
            }
          }

          if (step.name == 'Dosage') {
            if (DosageForm.valid) {
              step.active = false;
              step.valid = true;
              steps[index + 1].active = true;
              return true;
            }
            else {
              step.hasError = true;
            }
          }

          if (step.name == 'Ingredients') {
            if (IngredientsForm.valid) {
              step.active = false;
              step.valid = true;
              steps[index + 1].active = true;
              return true;
            }
            else {
              step.hasError = true;
            }
          }

        }
      }
    });

    

    this.details.productname      = this.ProductForm.value.productname;
    this.details.Pack1            = this.ProductForm.value.Pack1      ;
    this.details.Price1           = this.ProductForm.value.Price1     ;
    this.details.Pack2            = this.ProductForm.value.Pack2      ;
    this.details.Price2           = this.ProductForm.value.Price2     ;
    this.details.Pack3            = this.ProductForm.value.Pack3      ;
    this.details.Price3           = this.ProductForm.value.Price3     ;
    this.details.Pack4            = this.ProductForm.value.Pack4      ;
    this.details.Price4           = this.ProductForm.value.Price4     ;
    this.details.ProductInfo      = this.ProductForm.value.ProductInfo;
    this.details.Benfits          = this.BenfitsForm.value.Benfits;
    this.details.RecommendedCrops = this.CropsForm.value.RecommendedCrops;
    this.details.Dosage           = this.DosageForm.value.Dosage;
    this.details.Ingredients = this.IngredientsForm.value.Ingredients;
    this.details.CategoryId = this.ProductForm.value.CategoryName.CategoryId;
    debugger
  }

  public prev() {
    if (this.steps[0].active)
      return false;
    this.steps.some(function (step, index, steps) {
      if (index != 0) {
        if (step.active) {
          step.active = false;
          steps[index - 1].active = true;
          return true;
        }
      }
    });
  }
  validatepri(e: any) {
    debugger
    let input;
    input = String.fromCharCode(e.which);
    return !!/[0-9]/.test(input);
  }

  filterCat(event) {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;
    for (let i = 0; i < this.Cats.length; i++) {
      let country = this.Cats[i];
      if (country.CategoryName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(country);
      }
    }

    this.filteredCats = filtered;
  }


  public confirm() {
    debugger
    //this.steps.forEach(step => step.valid = true);
    this.steps[0].active = true;
   
    //this.confirmed = true;
    let Details1 = [];
    Details1.push({
       productname         :this.details.productname      
      ,Pack1               :this.details.Pack1           
      ,Price1              :this.details.Price1          
      ,Pack2               :this.details.Pack2           
      ,Price2              :this.details.Price2          
      ,Pack3               :this.details.Pack3           
      ,Price3              :this.details.Price3          
      ,Pack4               :this.details.Pack4           
      ,Price4              :this.details.Price4          
      , ProductInfo        :(this.details.ProductInfo)
      ,Benefits            :(this.details.Benfits)   
      ,RecommendedCrops    :(this.details.RecommendedCrops)
      , Dosage:(this.details.Dosage)        
      , Ingredients: (this.IngredientsForm.value.Ingredients)
      , CategoryId: this.details.CategoryId

    })


    const frmData = new FormData();
    frmData.append("ProdJson", JSON.stringify(Details1));
    frmData.append("ProdImages", JSON.stringify(this.ImageArray));
    var url = this.appservice.HomeUrl + 'api/Farmer/YK_InsertProducts';
    this.http.post(url, frmData).subscribe((obj: Response) => {
      // this.appservice.PostData(url, frmData).subscribe((data: Response) => {
      debugger
      var data = obj.json();


    //var url = this.appservice.HomeUrl + "api/Farmer/YK_InsertProducts?ProdJson=" + JSON.stringify(Details1) +'&ProdImages='+ JSON.stringify(this.ImageArray);
    //this.http.get(url).subscribe((obj: Response) => {
      debugger
      if (data == 'SUCCESS') {
        debugger
        this.urls = [];
        this.steps.forEach(step => step.active = false);
        this.steps.forEach(step => step.valid = false);
        this.steps[0].active = true;
        this.details = [];
        this.ProductForm.reset();
        this.BenfitsForm.reset();
        this.DosageForm.reset();
        this.IngredientsForm.reset();
        this.CropsForm.reset();


        this.messageservice.add({ severity: 'success', summary: 'SUCCESS', detail: 'Product Added Successfully ' });

      }
      else {
        this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Something went wrong' });

      }
      
    })


  }


  onReady(event: any) {

  }
  onFocus(event: any) {

  }
  onBlur(event: any) {

  }
  onChange(event: any) {
    setTimeout(() => {
      this.ckeditorContent = event;
    });
  }


}
