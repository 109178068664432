import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { Http, Response } from '@angular/http'
import { map, throttle, debounce } from 'rxjs/operators';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  Cols: any;
  Notifications: any;
  msg: any;
  spin: boolean = false;
  constructor(public appservice: AppService, public http: Http) {
    this.Cols = [
      { field: 'CreatedDate', header: 'Sent Date', width: '30px' },
      { field: 'Notification', header: 'Notifications', width: '120px' },

    ]
  }

  ngOnInit() {
    this.GetNotifications();
  }
  GetNotifications() {
   
    this.appservice.getNotifications().subscribe(data => {
      debugger
    
      if (data != null) {
        this.Notifications = data;
      }
    });
  }

  SendNotification(msg) {
    debugger
    if (this.msg == '') {
      alert('Notification message should not be empty.')
    }
    else {
      let arr = [];
      arr.push({ Useid: 1, Notification: this.msg, CreatedBy: 1 })
      this.spin = true;
      this.AddNotificationService(arr).subscribe((res: any) => {
        debugger
        if (res == "SUCCESS") {       
          this.spin = false;
          this.ngOnInit();
          this.msg = '';
          //var url = 'http://localhost:46812/api/Farmer/SendNotificationTo_All?Text=' + msg
          var url = 'https://yklaboratories.com/webservices/api/Farmer/SendNotificationTo_All?Text='+msg;
          this.http.get(url).subscribe((data: any) => {
            debugger           
            data       
        

          });
        }
        console.log(res)

      })
    
        
      
    
     
    }
  }

  AddNotificationService(arr) {
    return this.http.get('https://yklaboratories.com/webservices/api/Farmer/AddNotifications?AddNotificationXML=' + JSON.stringify(arr))
      .pipe(map((res: Response) => <any>res.json()));
  }
}
