import { Component, OnInit, NgZone } from '@angular/core';

import { Http, Headers, RequestOptions } from '@angular/http';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map, throttle, window } from 'rxjs/operators';
import { UserserviceService } from '../userservice.service';
import { AppService } from '../app.service';
import { MessageService } from 'primeng/api';
declare var $scope: any;
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  productid: any; Pack1: any;  Homeurl: any;   productDetails: any;  ProductImages: any;  Title: any;  Price: any;  ProductInfo: any;  RecommendedCrops: any;
  Dosage: any;  Dripirrigation: any;  Benefits: any;  Composition: any;  Curative: any;  Usages: any;
  Pack3: any;  Pack2: any;  Pack4: any;  Price1: any;  Price2: any;  Price3: any;  Price4: any;  Preventive: any;  addedItems: any;
  addtocartItem: any;  Ingredients: any;  pack: any;  products: any = [];
  colorchange: boolean = false;
  colorchange1: boolean = false;
  colorchange2: boolean = false;
  colorchange3: boolean = false;
  public color = "red";
  CategoryName: any; LoginDetails: any; type: any='1';
  ProductCategories: any;
  Products: any;
  categorizedProducts: any[];
  AllPacks: any;
  info: any = '';
  AmazonURL: any;
  constructor(public router: Router, public http: Http,    public route: ActivatedRoute,    public zone: NgZone,    public appservice: AppService,
    public messageservice: MessageService,    public userservice: UserserviceService) {
    this.Homeurl = localStorage.getItem("URL");
    //this.productid = this.route.snapshot.paramMap.get('id');
    this.LoginDetails = JSON.parse(sessionStorage.getItem("LoginDetails"));
    if (this.LoginDetails != null) {
      this.type = this.LoginDetails.LoginType;
    }
    this.route.params.subscribe(params => {
      this.productid = params['id'];
      this.zone.run(() => { this.getProductInformation(); });
    });
  }

  ngOnInit() {
    this.getProductCategories();
    this.getProducts();
   
  }


 
  saveData(value) {
    debugger
    this.info = '';

 //  = value;
  }
  getProductInformation() {
    debugger
    this.colorchange= false;
    this.colorchange1 = false;
    this.colorchange2= false;
    this.colorchange3= false;
    var contacturl = this.Homeurl + 'api/Farmer/GetAllCategoryWiseProducts?productid=' + this.productid;
    this.http.get(contacturl).pipe(map(res => res.json())).subscribe((data) => {
      debugger
      this.productDetails = data.Products[0];
      this.Title = this.productDetails.productname;
      this.CategoryName = this.productDetails.CategoryName;
      this.Price = this.productDetails.Price1;
      this.ProductInfo = this.productDetails.ProductInfo;
      this.RecommendedCrops = this.productDetails.RecommendedCrops;
      this.Dosage = this.productDetails.Dosage;
      this.Dripirrigation = this.productDetails.Dripirrigation;
      this.Benefits = this.productDetails.Benefits;
      this.Composition = this.productDetails.Composition;
      this.Curative = this.productDetails.Curative;
      this.Usages = this.productDetails.Usages;
      this.Preventive = this.productDetails.Preventive;
      this.colorchange = true;
      this.AllPacks = this.productDetails
      this.Pack1 = this.productDetails.Pack1;
      this.Pack2 = this.productDetails.Pack2;
      this.Pack3 = this.productDetails.Pack3;
      this.Pack4 = this.productDetails.Pack4;
      this.Price1 = this.productDetails.Price1;
      this.Price2 = this.productDetails.Price2;
      this.Price3 = this.productDetails.Price3;
      this.Price4 = this.productDetails.Price4;
      this.Ingredients = this.productDetails.Ingredients;
      this.AmazonURL = this.productDetails.AmazonURL;
      this.ProductImages = data.ProductImages;
      
      document.getElementById('nav-home-tab').click();
    });
  }

  ChangePrice(pack) {
    debugger
    this.colorchange = false;
    this.colorchange1 = false;
    this.colorchange2 = false;
    this.colorchange3 = false;
    switch (pack) {
      case 1: {
        this.Price = this.Price1;
        this.pack = this.Pack1;
        this.colorchange = true;
        this.colorchange1 = false;
        this.colorchange2 = false;
        this.colorchange3 = false;
        break;
      }
      case 2: {
        this.Price = this.Price2;
        this.pack = this.Pack2;
        this.colorchange1 = true;
        this.colorchange = false;
        this.colorchange2 = false;
        this.colorchange3 = false;
        break;
      }
      case 3: {
        this.Price = this.Price3;
        this.pack = this.Pack3;
        this.colorchange2 = true;
        this.colorchange1 = false;
        this.colorchange = false;
        this.colorchange3 = false;
        break;
      }
      case 4: {
        this.Price = this.Price4;
        this.pack = this.Pack4;
        this.colorchange3 = true;
        this.colorchange1 = false;
        this.colorchange2 = false;
        this.colorchange = false;
        break;
      }
    }
  }

  AddtoCart(productDetails, Price) {
    debugger
    this.products = [];

    if (Price == this.productDetails.Price1) {
      this.pack = this.productDetails.Pack1;

    }
    else if (Price == this.productDetails.Price2) {
      this.pack = this.productDetails.Pack2;
    }
    else if (Price == this.productDetails.Price3) {
      this.pack = this.productDetails.Pack3;
    }
    else if (Price == this.productDetails.Price4) {
      this.pack = this.productDetails.Pack4;
    }
    //////////////
    if (this.productDetails.Pack1 == this.pack) {
      this.products.push({ productname: productDetails.productname, RecommendedCrops: productDetails.RecommendedCrops, Productid: productDetails.Productid, Pack: this.pack, Price: Price, ItemTotalPrice: Price, ItemQuantity: 1, Images: this.ProductImages[0].Image });

    }
    else if (this.productDetails.Pack2 == this.pack) {
      this.products.push({ productname: productDetails.productname, RecommendedCrops: productDetails.RecommendedCrops, Productid: productDetails.Productid, Pack: this.pack, Price: Price, ItemTotalPrice: Price, ItemQuantity: 1, Images: this.ProductImages[0].Image });
    }
    else if (this.productDetails.Pack3 == this.pack) {
      this.products.push({ productname: productDetails.productname, RecommendedCrops: productDetails.RecommendedCrops, Productid: productDetails.Productid, Pack: this.pack, Price: Price, ItemTotalPrice: Price, ItemQuantity: 1, Images: this.ProductImages[0].Image });
    }
    else if (this.productDetails.Pack4 == this.pack) {
      this.products.push({ productname: productDetails.productname, RecommendedCrops: productDetails.RecommendedCrops, Productid: productDetails.Productid, Pack: this.pack, Price: Price, ItemTotalPrice: Price, ItemQuantity: 1, Images: this.ProductImages[0].Image });
    }
    this.addedItems = JSON.parse(localStorage.getItem('CartItem'));
    this.addtocartItem = this.addedItems;
    var check = true;
    if (this.addedItems != null) {
      for (var i = 0; i < this.addedItems.length; i++) {
        debugger
        if (this.addedItems[i].Productid == this.products[0].Productid && this.addedItems[i].Pack == this.products[0].Pack) {
          this.messageservice.add({ severity: 'warn', summary: 'Warning', detail: 'Item Already exists' });

          check = false;
        }
      }
      if (check == true) {
        debugger
        //productDetails.ItemQuantity = 1;
        //productDetails.ItemTotalPrice = Price;
        // productDetails.Images = this.ProductImages[0].Image;
        //this.products.ItemQuantity = 1;
        //this.products.ItemTotalPrice = Price;
        //this.products.Images = this.ProductImages[0].Image;
        this.addtocartItem.push(this.products[0]);
        this.RefreshPage();
        this.messageservice.add({ severity: 'success', summary: 'SUCCESS', detail: 'Item Added in Your cart Successfully' });
      }
    }
    else {
      debugger
      this.addtocartItem = []
      this.addtocartItem.push(this.products[0]);
      this.messageservice.add({ severity: 'success', summary: 'SUCCESS', detail: 'Item Added in Your cart Successfully' });

      this.RefreshPage();
    }
  }

  RefreshPage() {
    debugger
    localStorage.setItem("CartItem", JSON.stringify(this.addtocartItem));
    debugger
    this.userservice.countuser(this.addtocartItem.length);
  }

  getProductCategories() {

    this.appservice.getCategories().subscribe(data => {
      debugger
      if (data != null) {
        this.ProductCategories = data;
      }
    });
  }

  getProducts() {
    this.appservice.getProducts().subscribe(data => {
      debugger
      if (data != null) {
        this.Products = data;
      }
    });
  }

  filterProducts(Categoryid: any) {
    debugger
    this.categorizedProducts = [];
    this.categorizedProducts = this.Products.filter(a => a.CategoryId == Categoryid);
  }
}

