import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rdnews',
  templateUrl: './rdnews.component.html',
  styleUrls: ['./rdnews.component.css']
})
export class RdnewsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
