import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { Http, Response } from '@angular/http'
import { map, throttle, debounce } from 'rxjs/operators';

@Component({
  selector: 'app-distributors',
  templateUrl: './distributors.component.html',
  styleUrls: ['./distributors.component.css']
})
export class DistributorsComponent implements OnInit {
  Distributors: any;
  Cols: any; count: any;
    Distributors1: any;
  constructor(public appservice: AppService, public http: Http) {
    this.Cols = [
      { field: 'CreatedDate', header: 'Created Date', width: '70px'},
      { field: 'FarmerName', header: 'Farmer Name', width: '80px' },
      { field: 'MobileNo', header: 'Mobile No', width: '50px' },
      { field: 'Address', header: 'Address', width: '90px' },
      { field: 'Longitude', header: 'Longitude', width: '50px' },
      { field: 'Lattitude', header: 'Lattitude', width: '50px' },

    ]
  }

  ngOnInit() {
    this.getDistributors();
  }
  getDistributors() {
    debugger;
    this.appservice.getCustomers().subscribe(data => {
      debugger
      if (data != null) {
        this.Distributors = data;
        this.Distributors1 = this.Distributors.filter(a => a.LoginType == '5');
        this.count = this.Distributors1.length;      
      }
    });
  }
}
