import { Component, OnInit, NgZone } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import {  Response } from '@angular/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map, throttle } from 'rxjs/operators';
import { AppService } from '../app.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/api';
declare var $: any;

 

@Component({
  selector: 'app-crop-guidance',
  templateUrl: './crop-guidance.component.html',
  styleUrls: ['./crop-guidance.component.scss'],
  providers: [ConfirmationService]
})
export class CropGuidanceComponent implements OnInit {
  Crops: any;
  Homeurl: string;
  CropImages: any;
  CropVideos: any;
  MobServiceUrl: string;
  spinner: boolean = false;
  cropid: any;
  Cropname: any;
  cities:any= [];
  IMG:any=""
  IMG1:any=""
    LoginDetails: any;
    type: any;
  urls: any;
  solnimg: any = [];
  scheduleImg: any = [];
  id: any = 0;
  msgs: Message[] = [];
  InfoImg: any = [];
  image: any;
  constructor(public router: Router, public zone: NgZone, public http: Http, public route: ActivatedRoute,
    public appservice: AppService, public sanitizer: DomSanitizer, public confirmationService:ConfirmationService) {
     debugger
    this.LoginDetails = JSON.parse(sessionStorage.getItem("LoginDetails"));
    if (this.LoginDetails != null) {
      this.type = this.LoginDetails.LoginType;
    }

    this.Homeurl = localStorage.getItem('URL');
    this.spinner = true;
    this.MobServiceUrl = 'https://www.yklaboratories.com/webservices/';
    
    this.route.params.subscribe(params => {
      this.cropid = params['Id'];
      this.Cropname = params['CropName'];
      //this.getCrops();
      this.zone.run(() => {
        this.GetCropDetails(this.cropid);
        this.getScheduleImg(this.cropid);
      });
    });

    
  }

  ngOnInit() {
   
   // $('#myModal').modal('hide');
    //this.image_gallry();
  //  this.GetCropDetails(this.cropid);
   
   // this.getScheduleImg();
   
    $('.dropdown-content').click(function () {
        $('.dropdown-content').hide();
      });
    $('.dropbtn').on('mouseenter', function () {
      $('.dropdown-content').show();
    });
   
  }
  apply() {
   

    //$('.modal').on('show.bs.modal', centerModal);
    //$(window).on("resize", function () {
    //  $('.modal:visible').each(centerModal);
    //});
  
  }
  confirm2(ID,flg) {
    debugger
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete Confirmation',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'Record deleted' }];
        this.DeleteIt(ID,flg);
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'You have rejected' }];
      }
    });
  }
  close() {
  }
  DeleteIt(id,flg){
    var url = this.appservice.HomeUrl + '/api/Farmer/YK_Update_Youtube_Image?Image_Url=""&CropId=' +id+ '&flag='+flg;
    this.http.get(url).subscribe((obj: Response) => {
      if (obj != null) {
        var data = obj.json();
        if (data == 'SUCCESS') {
          alert('Image Deleted Successfully');         
          this.id = 0;
          this.IMG = "";
          this.IMG1 = "";
          if (flg == 9 || flg == 10 ) {
            this.GetCropDetails(this.cropid);
          }
          else if (flg == 8 || flg == 7 || flg == 13) {
            this.getScheduleImg(this.cropid);
          }
         
       
        }
      }
    })
  }
  showModal(img) {
    this.image = img;
    $('#myModal22').modal('show');

  }
  detectFiles(event) {
    this.IMG=""
    this.IMG1=""
    debugger
    this.urls = [];
    let files = event.target.files;
    if (files) {
      for (let file of files) {
        let reader = new FileReader();
        reader.onload = (e: any) => {
          this.urls.push(e.target.result);
        }
        reader.readAsDataURL(file);
      }
      this.fileChange(event);
    }

  }
  fileChange(event) {
    debugger  
    let fileList = event.target.files;
    if (fileList.length > 0) {
      const frmData = new FormData();     
      frmData.append("UploadedImage", fileList[0]);
        var url = this.appservice.HomeUrl + '/api/Farmer/ImageUpload';
        this.http.post(url, frmData).subscribe((obj: Response) => {      
          debugger
          var data = obj.json();
          if (data != null) {
            debugger
            this.IMG1 = data;
            this.IMG = this.appservice.HomeUrl+data
          }
        });
      
    }
  }

  AddImage(flg) {
    var url = this.appservice.HomeUrl + '/api/Farmer/YK_Update_Youtube_Image?Image_Url=' + this.IMG1 + '&CropId=' + this.cropid + '&flag=' + flg;
    this.http.get(url).subscribe((obj: Response) => {
      if (obj != null) {
        var data = obj.json();
        if (data == 'SUCESS') {
          alert('Your Image Uploaded Successfully');
          this.IMG = "";
          this.IMG1 = "";
          this.id = 0;
          if (flg == 2 || flg == 1) {
            this.GetCropDetails(this.cropid);
          }
          else if (flg == 3 || flg == 4 || flg == 11) {
            this.getScheduleImg(this.cropid);
          }
         
        }
      }
    })

  }
  AddUrl(url2) {
    debugger
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url2.match(regExp);    
    var YURL = 'https://www.youtube.com/embed/' + match[2];
    var url = this.appservice.HomeUrl + '/api/Farmer/YK_Update_Youtube_Image?Image_Url=' + YURL + '&CropId=' + this.cropid + '&flag=' + 1;
    this.http.get(url).subscribe((obj: Response) => {
      if (obj != null) {
        var data = obj.json();
        if (data == 'SUCESS') {
          alert('Your Youtube Url Uploaded Successfully');
          this.IMG = "";
          this.IMG1 = "";
          this.id = 0;
          this.GetCropDetails(this.cropid);
        }
      }
    })
  }
  
  

  GetCropDetails(Id) {
    this.spinner = true;
    
    var imagesurl = this.MobServiceUrl + 'api/Farmer/Get_ImageGallery?CropId=' + Id;
    this.http.get(imagesurl).pipe(map(res => res.json())).subscribe((data) => {
      debugger
      if (data != null) {
        this.CropImages = data;
      }
      var videosurl = this.MobServiceUrl + 'api/Farmer/Get_Youtubevideogallery?CropId=' + Id;
      this.http.get(videosurl).pipe(map(res => res.json())).subscribe((data) => {
        
        if (data != null) {
          this.CropVideos = data;
          this.spinner = false;
        }
      });
      document.getElementById("benefits_usages").click();
    });
  }

 
  getScheduleImg(id) {
    var url = this.appservice.HomeUrl + '/api/Farmer/Get_Solution_Schedule_InfoImageGallery?FLagId=1&CropId=' + id
    this.http.get(url).subscribe((obj: Response) => {
      if (obj != null) {
        var data = obj.json();
        this.InfoImg = data.InfoList;

        this.scheduleImg = data.ScheduleList;

        this.solnimg = data.SolutionList;
      }
    });
  }

  image_gallry() {

    $(document).ready(function () {
      $(".fancybox").fancybox({
        openEffect: "none",
        closeEffect: "none"
      });

      $(".zoom").hover(function () {

        $(this).addClass('transition');
      }, function () {

        $(this).removeClass('transition');
      });
    });

  }
  getCrops() {
    debugger
    this.appservice.getCrops().subscribe(data => {
      debugger
      if (data != null) {
        this.Crops = data;
      }
    });
  }
}
